import { CartResponse }                       from '@/types/cart';
import { KlarnaPaymentMethod, PaymentMethod } from '@/types/payments';

export function getActivePaymentMethods(
    paymentMethods: PaymentMethod[],
    totalPrice: number,
    cartHasAssembly: boolean,
    availableKlarnaMethods?: KlarnaPaymentMethod[],
) {
    return paymentMethods.filter((method) => isPaymentActive(method, totalPrice, cartHasAssembly, availableKlarnaMethods));
}

export function isPaymentActive(
    paymentMethod: PaymentMethod,
    totalPrice: number,
    cartHasAssembly: boolean,
    availableKlarnaMethods?: KlarnaPaymentMethod[],
): boolean {
    if (!paymentMethod.active) {
        return false;
    }

    if (paymentMethod.minPrice !== null && totalPrice < paymentMethod.minPrice) {
        return false;
    }

    // noinspection RedundantIfStatementJS
    if (paymentMethod.maxPrice !== null && totalPrice > paymentMethod.maxPrice) {
        return false;
    }

    if (!paymentMethod.assemblyOrderable && cartHasAssembly) {
        return false;
    }

    if (paymentMethod.isKlarna && availableKlarnaMethods) {
        return availableKlarnaMethods.filter(klarnaMethod => klarnaMethod.id === paymentMethod.id).length > 0;
    }

    return true;
}

export function isCartModified(newCart: CartResponse | null, oldCart: CartResponse | null): boolean {
    // If old cart is initially null, the store may be just loading the values, so we consider it "not changed"
    if (oldCart === null && newCart !== null) {
        return false;
    }

    // Customer may have logged out, or finished purchasing the cart
    if (newCart === null) {
        return true;
    }

    const newCartHasVoucher = !!newCart.voucher;
    const oldCartHasVoucher = !!oldCart?.voucher;

    if (newCartHasVoucher !== oldCartHasVoucher) {
        return true;
    }

    const newCartTotalQuantity = newCart?.cartItems
        .map(cartItem => cartItem.quantity || 0)
        .reduce(
            (accumulator, quantity) => accumulator + quantity,
            0,
        );
    const oldCartTotalQuantity = oldCart?.cartItems
        .map(cartItem => cartItem.quantity || 0)
        .reduce(
            (accumulator, quantity) => accumulator + quantity,
            0,
        );

    if (newCartTotalQuantity !== oldCartTotalQuantity) {
        return true;
    }

    const newCartTotalAmount = parseFloat((newCart?.cartAmount || 0) + '').toFixed(2);
    const oldCartTotalAmount = parseFloat((oldCart?.cartAmount || 0) + '').toFixed(2);

    return newCartTotalAmount !== oldCartTotalAmount;
}
